import { default as _91slug_93ei2NuucyyOMeta } from "/home/forge/uzmandangelsin.com/pages/[slug].vue?macro=true";
import { default as addressyyvD8y25xJMeta } from "/home/forge/uzmandangelsin.com/pages/account/address.vue?macro=true";
import { default as _91id_93dnkGXxZaHUMeta } from "/home/forge/uzmandangelsin.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_938VEmrO4GtZMeta } from "/home/forge/uzmandangelsin.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexdixlRstND9Meta } from "/home/forge/uzmandangelsin.com/pages/account/orders/index.vue?macro=true";
import { default as reviewWts3kFru4GMeta } from "/home/forge/uzmandangelsin.com/pages/account/review.vue?macro=true";
import { default as _91id_936KPzHEV3uIMeta } from "/home/forge/uzmandangelsin.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexudqkHkVYZVMeta } from "/home/forge/uzmandangelsin.com/pages/account/statement/index.vue?macro=true";
import { default as userWcfswtLU7XMeta } from "/home/forge/uzmandangelsin.com/pages/account/user.vue?macro=true";
import { default as wishlistYmbZ9LX7LEMeta } from "/home/forge/uzmandangelsin.com/pages/account/wishlist.vue?macro=true";
import { default as cart1aobTtWxtQMeta } from "/home/forge/uzmandangelsin.com/pages/cart.vue?macro=true";
import { default as indexNTrGGm7eIOMeta } from "/home/forge/uzmandangelsin.com/pages/checkout/index.vue?macro=true";
import { default as paymentu6YJKzRw7aMeta } from "/home/forge/uzmandangelsin.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientdoEin4DYrHMeta } from "/home/forge/uzmandangelsin.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/uzmandangelsin.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as request3DWCI3KSrZMeta } from "/home/forge/uzmandangelsin.com/pages/customer/request.vue?macro=true";
import { default as successryIQPX41MgMeta } from "/home/forge/uzmandangelsin.com/pages/customer/success.vue?macro=true";
import { default as iletisim2qabuvWP9SMeta } from "/home/forge/uzmandangelsin.com/pages/iletisim.vue?macro=true";
import { default as indexTSydYUuKAnMeta } from "/home/forge/uzmandangelsin.com/pages/index.vue?macro=true";
import { default as loginbomIX7gkfOMeta } from "/home/forge/uzmandangelsin.com/pages/login.vue?macro=true";
import { default as offerkYySAMQU29Meta } from "/home/forge/uzmandangelsin.com/pages/offer.vue?macro=true";
import { default as statusullhoEkbtAMeta } from "/home/forge/uzmandangelsin.com/pages/order/status.vue?macro=true";
import { default as trackH84BtB4H6lMeta } from "/home/forge/uzmandangelsin.com/pages/order/track.vue?macro=true";
import { default as forgot5AumqkCWJvMeta } from "/home/forge/uzmandangelsin.com/pages/password/forgot.vue?macro=true";
import { default as reset6YrxCBVmFIMeta } from "/home/forge/uzmandangelsin.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationPlTWmSEWxtMeta } from "/home/forge/uzmandangelsin.com/pages/payment-notification.vue?macro=true";
import { default as summarySZwqCoDLVRMeta } from "/home/forge/uzmandangelsin.com/pages/payment/summary.vue?macro=true";
import { default as registertB4U83kbyxMeta } from "/home/forge/uzmandangelsin.com/pages/register.vue?macro=true";
import { default as searchDeg5RrTccTMeta } from "/home/forge/uzmandangelsin.com/pages/search.vue?macro=true";
import { default as locationsZEgxClQzZIMeta } from "/home/forge/uzmandangelsin.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93ei2NuucyyOMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressyyvD8y25xJMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93dnkGXxZaHUMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_938VEmrO4GtZMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexdixlRstND9Meta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewWts3kFru4GMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_936KPzHEV3uIMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexudqkHkVYZVMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userWcfswtLU7XMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistYmbZ9LX7LEMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/uzmandangelsin.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexNTrGGm7eIOMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentu6YJKzRw7aMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/uzmandangelsin.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/uzmandangelsin.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/uzmandangelsin.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/uzmandangelsin.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexTSydYUuKAnMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginbomIX7gkfOMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerkYySAMQU29Meta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/uzmandangelsin.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/uzmandangelsin.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgot5AumqkCWJvMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: reset6YrxCBVmFIMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/uzmandangelsin.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/uzmandangelsin.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registertB4U83kbyxMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchDeg5RrTccTMeta || {},
    component: () => import("/home/forge/uzmandangelsin.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/uzmandangelsin.com/modules/locationsPage/pages/locations.vue")
  }
]